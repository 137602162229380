<template>
    <div class="row  q-px-lg">
        <div class="col-sm-12 col-lg-8 q-mx-auto">
        <p><strong class="title text-h3">Termini e Condizioni Generali di Vendita</strong></p>
<p><strong class="text-h5"><br /> Art. 1 - Disposizioni generali</strong></p>
<ol>
<li> L'utente navigando in quest'area accede ad areaplan.cloud, accessibile mediante l'url: www.areaplan.cloud (d'ora in avanti chiamato "AREAPLAN"). La navigazione e la sottoscrizione di abbonamento ai servizi della piattaforma areaplan.cloud comportano l'accettazione delle Condizioni e delle Politiche di Protezione dei Dati adottate dal sito stesso ivi indicate.</li>
<li>Le presenti Condizioni Generali di Vendita si applicano alla fornitura di servizi con esclusivo riferimento agli acquisti effettuati sul sito conformemente alle disposizioni della Parte III, Titolo III, Capo I, Codice del Consumo (D.lgs. n. 206/05 modificato dal D.lgs. n. 21/14 e D.lgs. 70/03) da parte di</li>
</ol>
<blockquote style="margin-left: 100px"> Societ&agrave; Cataimm s.r.l.&nbsp; &nbsp;<br /> Sede: Via Giardini 476/N - 41124 Modena (Italia)&nbsp; &nbsp;<br /> Partita IVA: 02981280361&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;<br /> Iscritta al REA, numero MO-347718</blockquote>
<ol start="3">
<li> L'utente &egrave; tenuto, prima di accedere ai servizi forniti dal sito, a leggere le presenti Condizioni Generali di Vendita che si intendono generalmente ed inequivocabilmente accettate al momento dell'acquisto.</li>
<li>Si invita l'utente a scaricare e stampare una copia del modulo d'acquisto e delle presenti Condizioni Generali di Vendita i cui termini AREAPLAN si riserva di modificare unilateralmente e senza alcun preavviso.</li>
</ol>
<p><strong class="text-h5">Art. 2 - Oggetto</strong></p>
<ol>
<li> Le presenti Condizioni Generali di Vendita disciplinano l'offerta, la sottoscrizione di abbonamento dei servizi offerti da AREAPLAN e non disciplinano, invece, la fornitura di servizi o la vendita di prodotti da parte di soggetti diversi dal venditore che siano presenti sul medesimo sito tramite link, banner o altri collegamenti ipertestuali.</li>
<li>Prima di sottoscrivere l&rsquo;abbonamento l&rsquo;utente &egrave; tenuto ad effettuare il periodo di prova gratuita di 15 giorni per verificare che il servizio sia adeguato alle proprie esigenze e aspettative.</li>
<li>La piattaforma permette all&rsquo;utente di calcolare i mq di una area in genere, ma in particolare il suo uso principale &egrave; quello di effettuare la misurazione dei mq commerciali di un immobile, in quanto l&rsquo;utente pu&ograve; utilizzare percentuali diverse in base alle proprie necessit&agrave;, esigenze e ambientazioni.</li>
<li>Il calcolo dei mq commerciali avviene solo dopo aver inserito una misura reale nel marcatore di scala, questo serve per determinare la distanza esatta di 2 punti e per permettere al sistema di attribuire la giusta scala di tutta la planimetria e delle aree disegnate.</li>
<li>La metratura calcolata &egrave; da considerarsi indicativa in quanto le variabili di errore possono essere diverse e molteplici, per questo motivo lo strumento non pu&ograve; essere utilizzato per certificare delle misurazioni, l&rsquo;utilizzatore o i suoi clienti manlevano AREAPLAN da qualsiasi responsabilit&agrave; in merito.</li>
<li>Le planimetrie possono essere salvate e rimangono disponibili sul proprio account per un periodo non superiore ai 6 mesi. Consigliamo comunque una esportazione del file pdf o la stampa immediata.</li>
<li>L&rsquo;accesso alla piattaforma &egrave; disponibile in qualsiasi momento della giornata e dell&rsquo;anno, non ne possiamo per&ograve; dare garanzia assoluta, in quanto potrebbe subire delle interruzioni a causa di blocchi del server o per manutenzione, ma in questo caso in nostri tecnici cercheranno di risolvere i problemi nel pi&ugrave; breve tempo possibile.</li>
</ol>
<p><strong class="text-h5">Art. 3 - Conclusione del contratto</strong></p>
<ol>
<li> Per concludere il contratto di abbonamento, sar&agrave; necessario compilare il formulario in formato elettronico e trasmetterlo seguendo le relative istruzioni.</li>
<li>In esso sono contenuti il rinvio alle Condizioni Generali di Vendita, le informazioni su ciascun servizio acquistato, i mezzi di pagamento che &egrave; possibile utilizzare, le condizioni per l'esercizio del diritto di recesso.</li>
<li>Prima di concludere il contratto, sar&agrave; chiesto di confermare l'avvenuta lettura delle Condizioni Generali di Vendita comprensive dell'Informativa sul diritto di recesso e del trattamento dei dati personali.</li>
<li>Il contratto &egrave; concluso quando il venditore o fornitore di servizi riceve il formulario compilato dall'utente, previa verifica della correttezza dei dati ivi contenuti.</li>
<li>L'utente sar&agrave; obbligato al pagamento del prezzo dell&rsquo;abbonamento dal momento in cui la procedura di sottoscrizione dell&rsquo;abbonamento sar&agrave; conclusa. Ci&ograve; avverr&agrave; cliccando sul tasto "abbonati o acquista l'abbonamento" alla fine della procedura guidata.</li>
<li>Concluso il contratto di abbonamento, il sistema prende in carico l'ordine e permette l&rsquo;accesso alla piattaforma per i 365 giorni successivi.</li>
</ol>
<p><strong class="text-h5">Art. 4 - Utenti registrati</strong></p>
<ol>
<li>Nel completare le procedure di registrazione, l'utente si obbliga a seguire le indicazioni presenti sul sito e a fornire i propri dati personali in maniera corretta e veritiera.</li>
<li>La conferma esonerer&agrave; in ogni caso AREAPLAN da ogni responsabilit&agrave; circa i dati forniti dall'utente. L'utente si obbliga a modificare tempestivamente i propri dati nell&rsquo;impostazione account su AREAPLAN qualora vi sia un errore o una variazione dei propri dati in ogni tempo comunicati.</li>
<li>Qualora poi l'utente comunichi dati non esatti o incompleti ovvero anche nel caso in cui vi sia contestazione da parte dei soggetti interessati circa i pagamenti effettuati, AREAPLAN avr&agrave; facolt&agrave; di non attivare ovvero di sospendere il servizio fino a sanatoria delle relative mancanze.</li>
<li>In occasione della prima richiesta di attivazione di un profilo da parte dell'utente, sar&agrave; l&rsquo;utente stesso ad impostare e scegliere il nome utente ed una password. Quest'ultimo riconosce che tali identificativi costituiscono il sistema di validazione degli accessi dell'utente ai servizi ed il solo sistema idoneo ad identificare l'utente che gli atti compiuti mediante tale accesso saranno a lui attribuiti e avranno efficacia vincolante nei suoi confronti.</li>
<li>L'utente si obbliga a mantenerne la segretezza dei suoi dati d'accesso e a custodirli con la dovuta cura e diligenza e a non cederli anche temporaneamente a terzi.</li>
</ol>
<p><strong class="text-h5">Art. 5 - Periodo di prova</strong></p>
<ol>
<li> AREAPLAN offre agli utenti la possibilit&agrave; di effettuare un periodo di prova gratuita per la durata di 15 giorni.</li>
<li>La fornitura del servizio cesser&agrave; alla fine del periodo di prova. Resta inteso che in caso di acquisto dell&rsquo;abbonamento durante il periodo di prova il Cliente dovr&agrave; sempre e comunque rispettare le condizioni previste dalle presenti condizioni per i singoli servizi.</li>
</ol>
<p><strong class="text-h5">Art. 6 &ndash; Durata abbonamento / Rinnovo automatico</strong></p>
<ol>
<li>Alla scadenza del periodo di prova, se si desidera continuare ad usufruire dei servizi della piattaforma, sar&agrave; necessario effettuare la sottoscrizione di un abbonamento annuale e una volta attivato, i servizi saranno disponibili per 1 anno intero, ovvero per i 365 giorni successivi.</li>
<li>L&rsquo;abbonamento avr&agrave; la durata di 1 anno e si rinnover&agrave; automaticamente fino a quando l&rsquo;utente lascia attivo il rinnovo automatico nel suo account.</li>
<li>I dati relativi alla carta di credito saranno memorizzati per la gestione dei successivi pagamenti dalla societ&agrave; STRIPE regolarmente certificata PCI- DSS che svolger&agrave; il servizio per conto di AREAPLAN.</li>
<li>In prossimit&agrave; della scadenza, verr&agrave; comunque inviato un avviso che informa dell&rsquo;avvicinarsi del rinnovo. L&rsquo;utente potr&agrave; annullare o interrompere il rinnovo dell&rsquo;abbonamento in qualsiasi momento fino all&rsquo;ultimo giorno utile.</li>
<li>Il rinnovo di abbonamento non prevede il diritto di recesso.</li>
</ol>
<p><strong class="text-h5">Art. 7 - Modalit&agrave; di pagamento e prezzi</strong></p>
<ol>
<li> Il prezzo dei servizi sar&agrave; quello indicato di volta in volta sul sito, salvo laddove sussista un errore palese.</li>
<li>In caso di errore AREAPLAN avviser&agrave; il prima possibile l'acquirente consentendo la conferma dell'ordine o dell&rsquo;abbonamento al giusto importo ovvero l'annullamento. Non sussister&agrave; ad ogni modo per AREAPLAN l'obbligo di fornire quanto venduto al prezzo inferiore erroneamente indicato.</li>
<li>I prezzi del sito sono da considerarsi al netto ai quali bisogna aggiungere l&rsquo;IVA. I prezzi possono subire variazioni in qualsiasi momento. I cambiamenti non riguardano gli ordini o abbonamenti per i quali sia stata gi&agrave; inviata conferma.</li>
<li>Una volta selezionato l&rsquo;abbonamento sar&agrave; sufficiente seguire le istruzioni per l'acquisto, inserendo o verificando le informazioni richieste in ogni passaggio del processo.</li>
<li>Il pagamento pu&ograve; essere effettuato solo tramite Carta di Credito</li>
</ol>
<p><strong class="text-h5"> Art. 8 - Consegna</strong></p>
<ol>
<li>I servizi forniti dalla pagina web saranno fruibili dal cliente immediatamente, cio&egrave; subito dopo la sottoscrizione di abbonamento, a condizione che vi sia il buon esito del pagamento.</li>
<li>I servizi saranno usufruibili online od inviati all'indirizzo email che il cliente ha fornito al momento dell'acquisto.</li>
<li>Qualora la consegna non possa avere luogo per cause non imputabili a nessuna della parti, l'utente verr&agrave; rimborsato totalmente del pagamento.</li>
</ol>
<p><strong class="text-h5"> Art. 9 &ndash; Diritto di recesso</strong></p>
<ol>
<li> Conformemente alle disposizioni legali in vigore, l'acquirente ha diritto di recedere dall'acquisto senza alcuna penalit&agrave; e senza specificarne il motivo, entro il termine di 14 giorni ai sensi dell'art. 57 del D.lgs 206/2005 decorrenti dalla data di inizio di fruizione dei servizi.</li>
<li>Il nostro periodo di prova di 15 giorni, offre la possibilit&agrave; all&rsquo;utente di provare il servizio senza nessun obbligo di acquisto, nel caso di acquisto dell&rsquo;abbonamento dopo i 15 giorni di prova, si considereranno questi giorni trascorsi alla pari del periodo del diritto di recesso, verranno quindi computati e considerati come tali.</li>
<li>Nel caso in cui l&rsquo;utente abbia sottoscritto un abbonamento prima della scadenza del periodo di prova dei 15 giorni, avr&agrave; facolt&agrave; di esercitare il diritto di recesso, entro i 14 giorni dalla data di inizio della prova gratuita. In questo caso dovr&agrave; comunicarlo ad AREAPLAN tramite dichiarazione esplicita, che potr&agrave; essere trasmessa a mezzo raccomandata A/R all'indirizzo:</li>
</ol>
<blockquote style="margin-left: 100px">Cataimm s.r.l. Via Giardini 476/N - 41124 Modena (Italia) - Servizio Areaplan</blockquote>
<blockquote style="margin-left: 100px">Oppure tramite PEC al seguente indirizzo: cataimm@pec.it</blockquote>
<ol start="4">
<li>AREAPLAN (ovvero Cataimm s.r.l.) eseguir&agrave; il rimborso utilizzando lo stesso mezzo di pagamento scelto dal l'acquirente in fase di acquisto.</li>
</ol>
<p><strong class="text-h5"> Art. 10 - Trattamento dei dati</strong></p>
<ol>
<li> I dati dell'acquirente sono trattati conformemente a quanto previsto dalla normativa in materia di protezione dei dati personali, come specificato nell'apposita sezione contenente l'informativa ai sensi dell'art. 13 Regolamento UE 2016/679 (Privacy Policy).</li>
</ol>
<p><strong class="text-h5">Art. 11 - Clausola di salvaguardia</strong></p>
<ol>
<li> Nell'ipotesi in cui una delle clausole presenti nelle Condizioni Generali di Vendita fosse nulla per qualsivoglia motivo ci&ograve; non comprometter&agrave; in nessun caso la validit&agrave; e il rispetto delle altre disposizioni figuranti nelle presenti Condizioni Generali di Vendita.</li>
</ol>
<p><strong class="text-h5">Art. 12 - Contatti</strong></p>
<ol>
<li> Ogni richiesta di informazione potr&agrave; essere inviata via mail al seguente indirizzo info@areaplan.cloud, via telefono al seguente recapito telefonico: +39 335 8381584, e via posta al seguente indirizzo: Cataimm srl Via Giardini 476/N - 41126 Modena (Italia)</li>
</ol>
<p><strong class="text-h5">Art. 13 - Legge applicabile e foro competente</strong></p>
<ol>
<li> Le presenti Condizioni Generali di Vendita sono disciplinate dal diritto italiano ed interpretate in base ad esso, fatta salva qualsiasi diversa norma imperativa prevalente del paese di residenza abituale del l'acquirente. Di conseguenza l'interpretazione, esecuzione e risoluzione delle Condizioni Generali di Vendita sono soggette esclusivamente alla legge Italiana.</li>
<li>Eventuali controversie inerenti e/o conseguenti alle stesse dovranno essere risolte in via esclusiva dall'autorit&agrave; giurisdizionale Italiana. In particolare, qualora l'utente rivesta la qualifica di Consumatore, le eventuali controversie dovranno essere risolte dal tribunale del luogo di domicilio o residenza dello stesso in base alla legge applicabile. Se invece l&rsquo;utente riveste la qualifica di Utente Business (cio&egrave; dove sia stata emessa fattura a societ&agrave; con partita iva), le eventuali controversie dovranno essere risolte nel Foro competente del Tribunale di Modena.</li>
</ol>
<p><br /> <br /> Le presenti condizioni sono state redatte in data 31/01/2021.</p>
    </div>
    </div>
</template>

<style scoped>
  p {
    margin-top: 30px;
  }
  li:not(:first-child) {
    margin-top: 10px;
  }
  .text-h5 {
    display: block;
    text-align: center;
  }
</style>
